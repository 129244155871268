import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Header from 'Components/headers/PageHeader';
import Features from "Components/Features/ThreeColumnPages";


import ECOM1 from 'images/ECOM-1.avif'
import ECOM2 from 'images/ECOM-2.avif'
import ECOM3 from 'images/ECOM-3.avif'
import ECOM4 from 'images/ECOM-4.avif'
import ECOM5 from 'images/ECOM-5.avif'
import ECOM6 from 'images/ECOM-6.avif'


import { FaShoppingCart, FaLock, FaBoxes } from "react-icons/fa";


import EcommerceIconImage from "images/ecommerce-icon.svg";
import PaymentGatewayIconImage from "images/secure.svg";
import InventoryIconImage from "images/inverntory.svg";
import ContactRightColum from "Components/Forms/ContactFormCol";
import styled from "styled-components";

const Subheading = tw.span`uppercase tracking-wider text-sm`;


const LeftColumn = tw.div`w-[95%] md:w-3/5 px-4 flex flex-col space-y-8`;
const RightColumn = styled.div`
  ${tw`w-full md:w-2/5 flex items-center justify-center mt-12 md:mt-0`}
`;

// Adjust TwoColumn to include overflow-hidden and ensure centering
const TwoColumn = styled.div`
  ${tw`flex flex-col md:flex-row justify-center items-stretch gap-10 max-w-screen-4xl mx-auto py-12 md:py-16 lg:py-16 overflow-hidden`}
`;

export default () => {
  return (
    <AnimationRevealPage>
  <Header from={"E-Commerce"}/>
  <TwoColumn>
    <LeftColumn>
  <MainFeature1
    subheading={<Subheading>E-commerce Solutions</Subheading>}
    heading="Elevate Your Online Store with MilaajBrandset"
    buttonRounded={false}
    primaryButtonText="Start Selling Online"
    textOnLeft={true}

    // imageSrcs={[
    //  ECOM1,
    //  ECOM2,
    //  ECOM3
    // ]}
    description="Transform your business with our comprehensive e-commerce solutions. At MilaajBrandset, we specialize in developing custom online stores that not only look great but also provide a seamless shopping experience for your customers."
    widths={["70%", "45%", "65%"]} 
    heights={["45%", "70%", "45%"]} 
    imageColumnStyle={`height: 400px;`}
    imageStyle={`width: 45%; height: 60%;`}
    imagePositions={[
      "left: 0%; top: 0%;",
      "right: 0%; top: 20%;",
      "left: 5%; bottom: 0%;",
    ]}
  />
  <MainFeature1
    subheading={<Subheading>Our Approach</Subheading>}
    heading="Customer-Centric E-commerce Development"
    buttonRounded={false}
    primaryButtonText="Learn More"
    // imageSrcs={[
    //  ECOM4,
    //  ECOM5,
    //  ECOM6
    // ]}
    description="Our approach to e-commerce development is centered around the customer. We focus on creating intuitive, engaging, and secure online stores that drive sales and enhance customer satisfaction. With MilaajBrandset, you can be assured of a user-friendly and high-performing e-commerce platform."
    textOnLeft={true}
    widths={["55%", "55%", "70%"]} 
    heights={["45%", "70%", "45%"]} 
    imageColumnStyle={`height: 400px;`}
    imageStyle={`width: 45%; height: 60%;`}
    imagePositions={[
      "left: 0%; top: 50%;",
      "right: 0%; top: 20%;",
      "left: -5%; bottom: 55%;",
    ]}
  />
  </LeftColumn>
  <RightColumn>
    <ContactRightColum />
  </RightColumn>
  </TwoColumn>
      <Features
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive E-commerce Solutions"
        description="MilaajBrandset offers end-to-end e-commerce development services to create powerful, scalable, and user-friendly online stores that drive your business growth. Our services are designed to cover every aspect of your e-commerce needs."
        cards={[
          {
            icon: <FaShoppingCart size={40} color="#ff5464" aria-label="Custom E-commerce Development" />,
            title: "Custom E-commerce Development",
            description: 
              "Tailor-made e-commerce platforms designed to align with your brand, offering a unique and engaging shopping experience.",
            // url: "/custom-e-commerce-development",
          },
          {
            icon: <FaLock size={40} color="#ff5464" aria-label="Secure Payment Integration" />,
            title: "Secure Payment Integration",
            description: 
              "Robust payment gateways integrated to support multiple methods, ensuring safe and seamless transactions for your customers.",
            // url: "/secure-payment-integration",
          },
          {
            icon: <FaBoxes size={40} color="#ff5464" aria-label="Inventory Management" />,
            title: "Inventory Management",
            description: 
              "Streamlined tools for tracking stock, managing orders, and keeping your inventory accurate and up-to-date effortlessly.",
            // url: "/inventory-management",
          },
        ]}
        
        linkText=""
      />
    </AnimationRevealPage>
  );
};
