import React, { useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import "swiper/swiper-bundle.css"; // Ensure you have the right CSS for Swiper
import "./App.css";

import Hero from "./Components/Hero/Hero.js";
import Features from "./Components/Features/DashedBorderSixFeatures.js";
import MainFeature from "./Components/Features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "./Components/Features/TwoColWithTwoFeaturesAndButtons.js";
import FAQ from "./Components/Faqs/SingleCol.js";
import ContactUsForm, { Overlay } from "./Components/Forms/TwoColContactUsWithIllustration.js";
import ContactUs from "Pages/ContactUs.js";
import Footer from "./Components/Footer/MiniCenteredFooter.js";
import Aboutus from "Pages/Aboutus.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.webp";
import Blog from "./Components/Blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import BlogIndex from "Pages/BlogIndex.js";
import DigitalMarketing from "Pages/DigitalMarketing.js";
import ApiDevelopment from "Pages/ApiDevelopment.js";
import WebDevelopment from "Pages/WebDevelopment.js";
import E_Commerce from "Pages/E_Commerce.js";
import Services from "Pages/Services.js";
import Faq from "Pages/Faq.js";
import SingleBlogPost from "Pages/BlogPostSingle.js";
import SeoPage from "Pages/seoPage.js";
import SocialMediaMarketing from "Pages/SocialMediaMarketing.js";
import GoogleAnalytics from "Pages/GoogleAnalytics.js";
import SocialMediaBranding from "Pages/SocialMediaBranding.js";
import EmailMarketing from "Pages/EmailMarketing.js";
import ContentMarketing from "Pages/ContentMarketing.js";
import CustomApi from "Pages/CustomApi.js";
import ApiSecurity from "Pages/ApiSecurity.js";
import ApiMaintanance from "Pages/ApiMaintanance.js";
import CustomWebDevelopment from "Pages/CustomWebDevelopment.js";
import ResponsiveWebDesign from "Pages/ResponsiveWebDesign.js";
import PerformanceOptimization from "Pages/PerformanceOptimization.js";
import CustomEcommerceDevelopment from "Pages/CustomE-commere.js";
import SecurePaymentIntegration from "Pages/SecurePaymentIntegration.js";
import InventoryManagement from "Pages/InventoryManagement.js";
import UI_UX from "Pages/UI_UX.js";
import UserResearchAnalysis from "Pages/UserResearchAnalysis.js";
import WireframingPrototyping from "Pages/WireframingPrototyping.js";
import UsabilityTesting from "Pages/UsabilityTesting.js";
import MultiStepForm from "Components/MultiStepForm/MultiStepForm";
import BoostYourBrand from "Pages/BoostYourBrand";
import TwoColumnList from "Components/Features/TwoColumnList";
import CustomCursor from "Components/Cursor/CustomCursor";
import Modal from "Components/Modal/Modal";
import BrandIdentityDevelopment from "Pages/BrandIdentityDevelopment";
import PaidMediaCampaigns from "Pages/PaidMediaCampaign";
import VideoAdProduction from "Pages/VideoAdProduction";




const HomePage = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleServiceClick = (service) => {
    setSelectedService(service);
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedService(null);
  };

  return (
    <>
      <Helmet>
        <title>Home | MilaajBrandset</title>
        <meta
          name="description"
          content="Welcome to MilaajBrandset. We provide top-notch digital solutions including web development, digital marketing, and more to help your business succeed online."
        />
        <meta
          name="keywords"
          content="MilaajBrandset, digital solutions, web development, digital marketing, SEO services, online business solutions"
        />
        <meta property="og:title" content="Home | MilaajBrandset" />
        <meta
          property="og:description"
          content="Welcome to MilaajBrandset. We provide top-notch digital solutions including web development, digital marketing, and more to help your business succeed online."
        />
      </Helmet>
      <Hero />
      <MainFeature />
      {/* Assigning id="features" to Features section */}
      <Features id="features" onServiceClick={handleServiceClick} />
      <MainFeature2 />
      <TwoColumnList features isBlack={true} black={false} />
      {/* <Blog /> */}
      <FAQ
        imageSrc={customerSupportIllustrationSrc}
        imageContain={true}
        imageShadow={false}
        subheading="FAQs"
        heading={
          <>
            Do you have <span tw="text-primary-500">Questions?</span>
          </>
        }
      />
      <ContactUsForm />

      {/* Render the Modal */}
      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <TwoColumnList black={true} selectedService={selectedService} />
        </Modal>
      )}
    </>
  );
};





const AboutPage = () => (
  <>
    <Helmet>
      <title>About Us | MilaajBrandset</title>
      <meta
        name="description"
        content="Learn more about MilaajBrandset, our values, and our mission to deliver exceptional digital solutions tailored to your business needs."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, about us, company information, digital solutions, business values"
      />
      <meta property="og:title" content="About Us | MilaajBrandset" />
      <meta
        property="og:description"
        content="Learn more about MilaajBrandset, our values, and our mission to deliver exceptional digital solutions tailored to your business needs."
      />
    </Helmet>
    <Aboutus />
  </>
);

const ContactPage = () => (
  <>
    <Helmet>
      <title>Contact Us | MilaajBrandset</title>
      <meta
        name="description"
        content="Get in touch with MilaajBrandset. Whether you have questions about our services or need support, we're here to help you."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, contact us, customer support, digital solutions, business inquiries"
      />
      <meta property="og:title" content="Contact Us | MilaajBrandset" />
      <meta
        property="og:description"
        content="Get in touch with MilaajBrandset. Whether you have questions about our services or need support, we're here to help you."
      />
    </Helmet>
    <ContactUs />
  </>
);

const ServicesPage = () => (
  <>
    <Helmet>
      <title>Services | MilaajBrandset</title>
      <meta
        name="description"
        content="Explore the wide range of digital services offered by MilaajBrandset, including web development, SEO, and more to grow your business."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, services, digital marketing, web development, SEO, business growth"
      />
      <meta property="og:title" content="Services | MilaajBrandset" />
      <meta
        property="og:description"
        content="Explore the wide range of digital services offered by MilaajBrandset, including web development, SEO, and more to grow your business."
      />
    </Helmet>
    <Services />
  </>
);

const MultiFormService = () => (
  <>
    <Helmet>
      <title>Services | MilaajBrandset</title>
      <meta
        name="description"
        content="Explore our comprehensive services, including branding, web design, digital marketing, and more, tailored to help your business grow."
      />
      <meta
        name="keywords"
        content="MilaajBrandset services, branding, web design, digital marketing, e-commerce solutions"
      />
      <meta property="og:title" content="Services | MilaajBrandset" />
      <meta
        property="og:description"
        content="Explore our services like branding, web design, digital marketing, and e-commerce solutions tailored for your business growth."
      />
    </Helmet>

    <MultiStepForm />
  </>
);

const MultiFormContact = () => (
  <>
    <Helmet>
      <title>Contact Us | MilaajBrandset</title>
      <meta
        name="description"
        content="Get in touch with MilaajBrandset to discuss your project or learn more about our services. We’re here to help!"
      />
      <meta
        name="keywords"
        content="MilaajBrandset contact, get in touch, business inquiries, customer support"
      />
      <meta property="og:title" content="Contact Us | MilaajBrandset" />
      <meta
        property="og:description"
        content="Reach out to MilaajBrandset to discuss your project or learn more about our services."
      />
    </Helmet>
    <MultiFormContact />
  </>
);

const BlogPage = () => (
  <>
    <Helmet>
      <title>Blog | MilaajBrandset</title>
      <meta
        name="description"
        content="Read our latest blog posts on digital marketing, web development, and other topics related to enhancing your business online presence."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, blog, digital marketing, web development, business insights, industry news"
      />
      <meta property="og:title" content="Blog | MilaajBrandset" />
      <meta
        property="og:description"
        content="Read our latest blog posts on digital marketing, web development, and other topics related to enhancing your business online presence."
      />
    </Helmet>
    <BlogIndex />
  </>
);

const DigitalMarketingPage = () => (
  <>
    <Helmet>
      <title>Digital Marketing | MilaajBrandset</title>
      <meta
        name="description"
        content="Discover MilaajBrandset's digital marketing services designed to enhance your online presence and drive business growth."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, digital marketing, SEO, online advertising, business growth"
      />
      <meta property="og:title" content="Digital Marketing | MilaajBrandset" />
      <meta
        property="og:description"
        content="Discover MilaajBrandset's digital marketing services designed to enhance your online presence and drive business growth."
      />
    </Helmet>
    <DigitalMarketing />
  </>
);

const ApiDevelopmentPage = () => (
  <>
    <Helmet>
      <title>API Development | MilaajBrandset</title>
      <meta
        name="description"
        content="Explore our API development services that provide custom solutions to integrate and enhance your business applications."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, API development, custom APIs, business integration, technology solutions"
      />
      <meta property="og:title" content="API Development | MilaajBrandset" />
      <meta
        property="og:description"
        content="Explore our API development services that provide custom solutions to integrate and enhance your business applications."
      />
    </Helmet>
    <ApiDevelopment />
  </>
);

const WebDevelopmentPage = () => (
  <>
    <Helmet>
      <title>Web Development | MilaajBrandset</title>
      <meta
        name="description"
        content="Our web development services offer cutting-edge solutions to build and optimize your website for better performance and user experience."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, web development, website design, user experience, digital solutions"
      />
      <meta property="og:title" content="Web Development | MilaajBrandset" />
      <meta
        property="og:description"
        content="Our web development services offer cutting-edge solutions to build and optimize your website for better performance and user experience."
      />
    </Helmet>
    <WebDevelopment />
  </>
);

const ECommercePage = () => (
  <>
    <Helmet>
      <title>E-Commerce | MilaajBrandset</title>
      <meta
        name="description"
        content="Boost your online sales with MilaajBrandset's e-commerce solutions designed to enhance your store's performance and customer experience."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, e-commerce solutions, online sales, store optimization, digital retail"
      />
      <meta property="og:title" content="E-Commerce | MilaajBrandset" />
      <meta
        property="og:description"
        content="Boost your online sales with MilaajBrandset's e-commerce solutions designed to enhance your store's performance and customer experience."
      />
    </Helmet>
    <E_Commerce />
  </>
);

const BrandIdentityDevelopmentPage = () => (
  <>
    <Helmet>
      <title>Brand Identity Development | MilaajBrandset</title>
      <meta
        name="description"
        content="Craft a distinctive brand identity that tells your story and resonates with your audience. MilaajBrandset focuses on creating a cohesive brand image that reflects your values and stands out in the market."
      />
      <meta
        name="keywords"
        content="Brand Identity Development, Logo Design, Brand Messaging, Style Guides, Brand Strategy, MilaajBrandset"
      />
      <meta property="og:title" content="Brand Identity Development | MilaajBrandset" />
      <meta
        property="og:description"
        content="Craft a distinctive brand identity that tells your story and resonates with your audience. MilaajBrandset focuses on creating a cohesive brand image that reflects your values and stands out in the market."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.milaajbrandset.com/brand-identity-development" />
      <meta property="og:image" content="https://www.milaajbrandset.com/images/brand-identity-og-image.jpg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Brand Identity Development | MilaajBrandset" />
      <meta
        name="twitter:description"
        content="Craft a distinctive brand identity that tells your story and resonates with your audience. MilaajBrandset focuses on creating a cohesive brand image that reflects your values and stands out in the market."
      />
      <meta name="twitter:image" content="https://www.milaajbrandset.com/images/brand-identity-twitter-image.jpg" />
      {/* Add more meta tags as needed */}
    </Helmet>
    <BrandIdentityDevelopment />
  </>
);

const SingleBlogPostPage = () => (
  <>
    <Helmet>
      <title>Blog Post | MilaajBrandset</title>
      <meta
        name="description"
        content="Read the latest insights and updates from MilaajBrandset's blog. Explore detailed articles and expert opinions on various topics."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, blog post, insights, updates, expert opinions"
      />
      <meta property="og:title" content="Blog Post | MilaajBrandset" />
      <meta
        property="og:description"
        content="Read the latest insights and updates from MilaajBrandset's blog. Explore detailed articles and expert opinions on various topics."
      />
    </Helmet>
    <SingleBlogPost />
  </>
);

const SocialMediaMarketingPage = () => (
  <>
    <Helmet>
      <title>Social Media Marketing | MilaajBrandset</title>
      <meta
        name="description"
        content="Enhance your social media presence with MilaajBrandset's social media marketing services. Drive engagement and growth across platforms."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, social media marketing, social media strategy, engagement, growth"
      />
      <meta
        property="og:title"
        content="Social Media Marketing | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Enhance your social media presence with MilaajBrandset's social media marketing services. Drive engagement and growth across platforms."
      />
    </Helmet>
    <SocialMediaMarketing />
  </>
);

const SEOpage = () => (
  <>
    <Helmet>
      <title>SEO Services | MilaajBrandset</title>
      <meta
        name="description"
        content="Boost your online visibility with MilaajBrandset's SEO services. Improve your website's search engine ranking and drive more traffic to your site."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, SEO services, search engine optimization, website ranking, online visibility"
      />
      <meta property="og:title" content="SEO Services | MilaajBrandset" />
      <meta
        property="og:description"
        content="Boost your online visibility with MilaajBrandset's SEO services. Improve your website's search engine ranking and drive more traffic to your site."
      />
    </Helmet>
    <SeoPage />
  </>
);

const VideoAdProductionPage = () => (
  <>
    <Helmet>
      <title>Video Ad Production | MilaajBrandset</title>
      <meta
        name="description"
        content="Create impactful video advertisements that tell your brand’s story. MilaajBrandset offers comprehensive video ad production services from concept to final edit, designed to engage and convert your audience."
      />
      <meta
        name="keywords"
        content="Video Ad Production, Concept Development, Scriptwriting, Professional Editing, Targeted Distribution, MilaajBrandset"
      />
      <meta property="og:title" content="Video Ad Production | MilaajBrandset" />
      <meta
        property="og:description"
        content="Create impactful video advertisements that tell your brand’s story. MilaajBrandset offers comprehensive video ad production services from concept to final edit, designed to engage and convert your audience."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.milaajbrandset.com/video-ad-production" />
      <meta property="og:image" content="https://www.milaajbrandset.com/images/video-ad-og-image.jpg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Video Ad Production | MilaajBrandset" />
      <meta
        name="twitter:description"
        content="Create impactful video advertisements that tell your brand’s story. MilaajBrandset offers comprehensive video ad production services from concept to final edit, designed to engage and convert your audience."
      />
      <meta name="twitter:image" content="https://www.milaajbrandset.com/images/video-ad-twitter-image.jpg" />
      {/* Add more meta tags as needed */}
    </Helmet>
    <VideoAdProduction />
  </>
);

const BoostBrandPage = () => (
  <>
    <Helmet>
      <title>Branding and Digital Solutions | MilaajBrandset</title>
      <meta
        name="description"
        content="Elevate your brand with MilaajBrandset's cutting-edge social media management, strategic brand positioning, and creative solutions. Let's make your brand shine."
      />
      <meta
        name="keywords"
        content="branding, social media management, digital solutions, MilaajBrandset, brand positioning, creative content, video production, paid media campaigns"
      />
      <meta
        property="og:title"
        content="Branding and Digital Solutions | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Elevate your brand with MilaajBrandset's cutting-edge social media management, strategic brand positioning, and creative solutions. Let's make your brand shine."
      />
      <meta
        property="og:image"
        content="path-to-image" /* Replace with actual image path */
      />
    </Helmet>
    <BoostYourBrand />
  </>
);

const GoogleAnalyticsPage = () => (
  <>
    <Helmet>
      <title>Google Analytics | MilaajBrandset</title>
      <meta
        name="description"
        content="Leverage Google Analytics with MilaajBrandset to track and analyze your website's performance and make data-driven decisions."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, Google Analytics, website performance, data analysis, business insights"
      />
      <meta property="og:title" content="Google Analytics | MilaajBrandset" />
      <meta
        property="og:description"
        content="Leverage Google Analytics with MilaajBrandset to track and analyze your website's performance and make data-driven decisions."
      />
    </Helmet>
    <GoogleAnalytics />
  </>
);
const CustomApiPage = () => (
  <>
    <Helmet>
      <title>Google Analytics | MilaajBrandset</title>
      <meta
        name="description"
        content="Leverage Google Analytics with MilaajBrandset to track and analyze your website's performance and make data-driven decisions."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, Google Analytics, website performance, data analysis, business insights"
      />
      <meta property="og:title" content="Google Analytics | MilaajBrandset" />
      <meta
        property="og:description"
        content="Leverage Google Analytics with MilaajBrandset to track and analyze your website's performance and make data-driven decisions."
      />
    </Helmet>
    <CustomApi />
  </>
);

const ApiSecurityPage = () => (
  <>
    <Helmet>
      <title>Google Analytics | MilaajBrandset</title>
      <meta
        name="description"
        content="Leverage Google Analytics with MilaajBrandset to track and analyze your website's performance and make data-driven decisions."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, Google Analytics, website performance, data analysis, business insights"
      />
      <meta property="og:title" content="Google Analytics | MilaajBrandset" />
      <meta
        property="og:description"
        content="Leverage Google Analytics with MilaajBrandset to track and analyze your website's performance and make data-driven decisions."
      />
    </Helmet>
    <ApiSecurity />
  </>
);

const ApiMaintainencePage = () => (
  <>
    <Helmet>
      <title>Google Analytics | MilaajBrandset</title>
      <meta
        name="description"
        content="Leverage Google Analytics with MilaajBrandset to track and analyze your website's performance and make data-driven decisions."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, Google Analytics, website performance, data analysis, business insights"
      />
      <meta property="og:title" content="Google Analytics | MilaajBrandset" />
      <meta
        property="og:description"
        content="Leverage Google Analytics with MilaajBrandset to track and analyze your website's performance and make data-driven decisions."
      />
    </Helmet>
    <ApiMaintanance />
  </>
);

const CustomWebDevelopmentPage = () => (
  <>
    <Helmet>
      <title>Custom Web Development | MilaajBrandset</title>
      <meta
        name="description"
        content="Get bespoke web development services with MilaajBrandset, tailored to meet your unique business needs and goals."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, custom web development, bespoke websites, business solutions"
      />
      <meta
        property="og:title"
        content="Custom Web Development | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Get bespoke web development services with MilaajBrandset, tailored to meet your unique business needs and goals."
      />
    </Helmet>
    <CustomWebDevelopment />
  </>
);

const ResponsiveWebDesignPage = () => (
  <>
    <Helmet>
      <title>Responsive Web Design | MilaajBrandset</title>
      <meta
        name="description"
        content="Enhance your website's usability with MilaajBrandset's responsive web design services, ensuring a seamless experience across all devices."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, responsive web design, mobile-friendly websites, user experience"
      />
      <meta
        property="og:title"
        content="Responsive Web Design | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Enhance your website's usability with MilaajBrandset's responsive web design services, ensuring a seamless experience across all devices."
      />
    </Helmet>
    <ResponsiveWebDesign />
  </>
);

const PerformanceOptimizationPage = () => (
  <>
    <Helmet>
      <title>Performance Optimization | MilaajBrandset</title>
      <meta
        name="description"
        content="Boost your website's speed and efficiency with MilaajBrandset's performance optimization services, delivering a faster, smoother user experience."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, performance optimization, website speed, user experience"
      />
      <meta
        property="og:title"
        content="Performance Optimization | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Boost your website's speed and efficiency with MilaajBrandset's performance optimization services, delivering a faster, smoother user experience."
      />
    </Helmet>
    <PerformanceOptimization />
  </>
);

const SocialMediaBrandingPage = () => (
  <>
    <Helmet>
      <title>Social Media Branding | MilaajBrandset</title>
      <meta
        name="description"
        content="Boost your brand's presence with MilaajBrandset's social media branding services. Create a strong and recognizable brand identity online."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, social media branding, brand identity, online presence, brand recognition"
      />
      <meta
        property="og:title"
        content="Social Media Branding | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Boost your brand's presence with MilaajBrandset's social media branding services. Create a strong and recognizable brand identity online."
      />
    </Helmet>
    <SocialMediaBranding />
  </>
);


const PaidMediaCampaignsPage = () => (
  <>
    <Helmet>
      <title>Paid Media Campaigns | MilaajBrandset</title>
      <meta
        name="description"
        content="Drive targeted traffic and maximize ROI with MilaajBrandset's data-driven paid media campaigns. We create and manage effective ads across multiple platforms to ensure your budget is utilized efficiently."
      />
      <meta
        name="keywords"
        content="Paid Media Campaigns, Precision Targeting, Cost Optimization, Comprehensive Tracking, Creative Designs, MilaajBrandset"
      />
      <meta property="og:title" content="Paid Media Campaigns | MilaajBrandset" />
      <meta
        property="og:description"
        content="Drive targeted traffic and maximize ROI with MilaajBrandset's data-driven paid media campaigns. We create and manage effective ads across multiple platforms to ensure your budget is utilized efficiently."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.milaajbrandset.com/paid-media-campaigns" />
      <meta property="og:image" content="https://www.milaajbrandset.com/images/paid-media-og-image.jpg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Paid Media Campaigns | MilaajBrandset" />
      <meta
        name="twitter:description"
        content="Drive targeted traffic and maximize ROI with MilaajBrandset's data-driven paid media campaigns. We create and manage effective ads across multiple platforms to ensure your budget is utilized efficiently."
      />
      <meta name="twitter:image" content="https://www.milaajbrandset.com/images/paid-media-twitter-image.jpg" />
      {/* Add more meta tags as needed */}
    </Helmet>
    <PaidMediaCampaigns />
  </>
);

const EmailMarketingPage = () => (
  <>
    <Helmet>
      <title>Email Marketing | MilaajBrandset</title>
      <meta
        name="description"
        content="Engage your audience effectively with MilaajBrandset's email marketing services. Craft compelling campaigns to drive customer engagement and retention."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, email marketing, email campaigns, customer engagement, retention"
      />
      <meta property="og:title" content="Email Marketing | MilaajBrandset" />
      <meta
        property="og:description"
        content="Engage your audience effectively with MilaajBrandset's email marketing services. Craft compelling campaigns to drive customer engagement and retention."
      />
    </Helmet>
    <EmailMarketing />
  </>
);

const ContentMarketingPage = () => (
  <>
    <Helmet>
      <title>Content Marketing | MilaajBrandset</title>
      <meta
        name="description"
        content="Drive business growth with MilaajBrandset's content marketing services. Create and distribute valuable content to attract and retain your target audience."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, content marketing, content strategy, audience engagement, business growth"
      />
      <meta property="og:title" content="Content Marketing | MilaajBrandset" />
      <meta
        property="og:description"
        content="Drive business growth with MilaajBrandset's content marketing services. Create and distribute valuable content to attract and retain your target audience."
      />
    </Helmet>
    <ContentMarketing />
  </>
);

const CustomEcommerceDevelopmentPage = () => (
  <>
    <Helmet>
      <title>Content Marketing | MilaajBrandset</title>
      <meta
        name="description"
        content="Drive business growth with MilaajBrandset's content marketing services. Create and distribute valuable content to attract and retain your target audience."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, content marketing, content strategy, audience engagement, business growth"
      />
      <meta property="og:title" content="Content Marketing | MilaajBrandset" />
      <meta
        property="og:description"
        content="Drive business growth with MilaajBrandset's content marketing services. Create and distribute valuable content to attract and retain your target audience."
      />
    </Helmet>
    <CustomEcommerceDevelopment />
  </>
);

const SecurePaymentIntegrationPage = () => (
  <>
    <Helmet>
      <title>Content Marketing | MilaajBrandset</title>
      <meta
        name="description"
        content="Drive business growth with MilaajBrandset's content marketing services. Create and distribute valuable content to attract and retain your target audience."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, content marketing, content strategy, audience engagement, business growth"
      />
      <meta property="og:title" content="Content Marketing | MilaajBrandset" />
      <meta
        property="og:description"
        content="Drive business growth with MilaajBrandset's content marketing services. Create and distribute valuable content to attract and retain your target audience."
      />
    </Helmet>
    <SecurePaymentIntegration />
  </>
);

const InventoryManagementPage = () => (
  <>
    <Helmet>
      <title>Content Marketing | MilaajBrandset</title>
      <meta
        name="description"
        content="Drive business growth with MilaajBrandset's content marketing services. Create and distribute valuable content to attract and retain your target audience."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, content marketing, content strategy, audience engagement, business growth"
      />
      <meta property="og:title" content="Content Marketing | MilaajBrandset" />
      <meta
        property="og:description"
        content="Drive business growth with MilaajBrandset's content marketing services. Create and distribute valuable content to attract and retain your target audience."
      />
    </Helmet>
    <InventoryManagement />
  </>
);
const UIUXPage = () => (
  <>
    <Helmet>
      <title>UI/UX Design Solutions | MilaajBrandset</title>
      <meta
        name="description"
        content="Enhance user experiences with MilaajBrandset's UI/UX design solutions. Discover our approach to creating intuitive and engaging user interfaces that drive results."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, UI design, UX design, user experience, user interface, design solutions, interactive design"
      />
      <meta
        property="og:title"
        content="UI/UX Design Solutions | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Enhance user experiences with MilaajBrandset's UI/UX design solutions. Discover our approach to creating intuitive and engaging user interfaces that drive results."
      />
    </Helmet>
    <UI_UX />
  </>
);
const UsabilityTestingPage = () => (
  <>
    <Helmet>
      <title>UI/UX Design Solutions | MilaajBrandset</title>
      <meta
        name="description"
        content="Enhance user experiences with MilaajBrandset's UI/UX design solutions. Discover our approach to creating intuitive and engaging user interfaces that drive results."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, UI design, UX design, user experience, user interface, design solutions, interactive design"
      />
      <meta
        property="og:title"
        content="UI/UX Design Solutions | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Enhance user experiences with MilaajBrandset's UI/UX design solutions. Discover our approach to creating intuitive and engaging user interfaces that drive results."
      />
    </Helmet>
    <UsabilityTesting />
  </>
);
const UserResearchAnalysisPage = () => (
  <>
    <Helmet>
      <title>UI/UX Design Solutions | MilaajBrandset</title>
      <meta
        name="description"
        content="Enhance user experiences with MilaajBrandset's UI/UX design solutions. Discover our approach to creating intuitive and engaging user interfaces that drive results."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, UI design, UX design, user experience, user interface, design solutions, interactive design"
      />
      <meta
        property="og:title"
        content="UI/UX Design Solutions | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Enhance user experiences with MilaajBrandset's UI/UX design solutions. Discover our approach to creating intuitive and engaging user interfaces that drive results."
      />
    </Helmet>
    <UserResearchAnalysis />
  </>
);
const WireframingPrototypingPage = () => (
  <>
    <Helmet>
      <title>UI/UX Design Solutions | MilaajBrandset</title>
      <meta
        name="description"
        content="Enhance user experiences with MilaajBrandset's UI/UX design solutions. Discover our approach to creating intuitive and engaging user interfaces that drive results."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, UI design, UX design, user experience, user interface, design solutions, interactive design"
      />
      <meta
        property="og:title"
        content="UI/UX Design Solutions | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Enhance user experiences with MilaajBrandset's UI/UX design solutions. Discover our approach to creating intuitive and engaging user interfaces that drive results."
      />
    </Helmet>
    <WireframingPrototyping />
  </>
);

const App = () => {
  const location = useLocation(); // Get the current path

  return (
    <AnimationRevealPage>
      <CustomCursor />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/blog/:id/:title" element={<SingleBlogPostPage />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/search-engine-optimization" element={<SEOpage />} />
        <Route
          path="/social-media-marketing"
          element={<SocialMediaMarketingPage />}
        />
        <Route path="/google-analytics" element={<GoogleAnalyticsPage />} />
        <Route
          path="/social-media-branding"
          element={<SocialMediaBrandingPage />}
        />
        <Route path="/boost-your-brand" element={<BoostBrandPage />} />
        <Route path="/email-marketing" element={<EmailMarketingPage />} />
        <Route path="/content-marketing" element={<ContentMarketingPage />} />
        <Route path="/custom-api-development" element={<CustomApiPage />} />
        <Route path="/api-security" element={<ApiSecurityPage />} />
        <Route
          path="/custom-web-development"
          element={<CustomWebDevelopmentPage />}
        />
        <Route
          path="/responsive-web-design"
          element={<ResponsiveWebDesignPage />}
        />
        <Route
          path="/performance-optimization"
          element={<PerformanceOptimizationPage />}
        />
        <Route path="/video-ad-production" element={<VideoAdProductionPage />} /> {/* SEO-Enhanced Route */}

        <Route
          path="/custom-e-commerce-development"
          element={<CustomEcommerceDevelopmentPage />}
        />
        <Route
          path="/secure-payment-integration"
          element={<SecurePaymentIntegrationPage />}
        />
        <Route path="/brand-identity-development" element={<BrandIdentityDevelopmentPage />} />

        <Route
          path="/inventory-management"
          element={<InventoryManagementPage />}
        />
        <Route path="/paid-media-campaigns" element={<PaidMediaCampaignsPage />} />

        <Route
          path="/api-maintenance-support"
          element={<ApiMaintainencePage />}
        />
        <Route path="/digital-marketing" element={<DigitalMarketingPage />} />
        <Route path="/api-development" element={<ApiDevelopmentPage />} />
        <Route path="/web-development" element={<WebDevelopmentPage />} />
        <Route path="/ui-ux-design" element={<UIUXPage />} />
        <Route
          path="/research-&-analysis"
          element={<UserResearchAnalysisPage />}
        />
        <Route
          path="/wireframing-&-prototyping"
          element={<WireframingPrototypingPage />}
        />
        <Route path="/usability-testing" element={<UsabilityTestingPage />} />
        <Route path="/e-commerce" element={<ECommercePage />} />
        <Route path="/grow" element={<MultiFormService />} />
      </Routes>
      <Footer />
    </AnimationRevealPage>
  );
};

export default () => (
  <Router>
    <App />
  </Router>
);
