import React, { useState } from "react";
import { Link } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { ChevronDown } from "lucide-react";
import Header from "Components/headers/header";
import Interactive from "Components/Cursor/Interactivs";

const VideoBackground = styled.video`
  ${tw`absolute inset-0 w-full h-full object-cover h-[700px]`}
  z-index: -1; /* Ensure the video stays behind other elements */
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center py-0 overflow-hidden`}
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-[rgba(0,0,0,0.6)]`;
const HeroContainer = tw.div`z-20 relative px-2 max-w-screen-xl mx-auto pt-8`;
const TwoColumn = tw.div`pt-24 pb-10 md:pb-32 px-4 mt-32 flex justify-center items-center flex-col`;
const LeftColumn = tw.div`flex flex-col items-center justify-between gap-y-10 lg:block text-center`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8 flex justify-center`;
const Heading = styled.h1`
  ${tw`text-2xl lg:text-center sm:text-4xl lg:text-2xl xl:text-6xl font-black leading-none`}
  font-family: 'Poppins', serif;
  background: linear-gradient(to bottom, #f5d7da, #ff5464);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  span {
    ${tw`inline-block mt-1`}
  }
`;

const NotificationContainer = tw.div`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;
const NotificationText = styled.span`
  ${tw`relative flex justify-center`}
  &::after {
    content: "";
    display: block;
    width: 50%;
    height: 2px; /* Adjust height as needed */
    background: white; /* Change to your desired color */
    position: absolute;
    left: 50%;
    transform: translateX(-50%); /* Center the underline */
    bottom: -5px; /* Adjust spacing between text and underline */
  }
`;
const PrimaryAction = styled(Link)`
  ${tw`px-16 py-8 md:py-4 xl:py-8  lg:py-8 mt-10 text-sm sm:text-base text-white sm:mt-16 sm:px-16 sm:py-4 
  font-bold shadow transition duration-300 
  hocus:text-customPurple focus:shadow-outline inline-block 
  rounded-full`}

  box-shadow:
  inset 0 -5px 10px rgba(255, 84, 100, 0.6),
  inset 0 0px rgba(255, 84, 100, 0.8),
  0 8px 24px rgba(0, 0, 0, 0.3),
  0 4px 12px rgba(0, 0, 0, 0.2);

  text-decoration: none;
  font-weight: 50px;
`;

const DropdownContainer = styled.div`
  ${tw`relative inline-block`}
`;

const DropdownButton = styled(Link)`
  ${tw`inline-flex items-center cursor-pointer`}
`;

const DropdownMenu = styled.div`
  ${tw`absolute left-0 w-56 mt-2 origin-top-left bg-gray-800 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
`;

const DropdownItem = styled(Link)`
  ${tw`block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white`}
`;

const ServicesDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  const services = [
    { title: "Digital Marketing", path: "/digital-marketing" },
    { title: "API Development", path: "/api-development" },
    { title: "Web Development", path: "/web-development" },
    { title: "E-Commerce", path: "/e-commerce" },
    { title: "UI/UX Design", path: "/ui-ux-design" },
  ];

  return (
    <DropdownContainer>
      <DropdownButton onClick={() => setIsOpen(!isOpen)}>
        Services <ChevronDown aria-hidden="true" />
      </DropdownButton>
      {isOpen && (
        <DropdownMenu>
          {services.map((service, index) => (
            <DropdownItem key={index} to={service.path}>
              {service.title}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default () => {
  return (
    <Container>
      <VideoBackground autoPlay muted loop>
        <source src="https://res.cloudinary.com/dhjdz1kjb/video/upload/v1734800351/hero5_ncwbk5.mp4" type="video/mp4" />
      </VideoBackground>
      <OpacityOverlay />
      <HeroContainer>
        <Header />
        <TwoColumn>
          <LeftColumn>
            <NotificationContainer>
              <NotificationText>
                Now serving clients worldwide!
              </NotificationText>
            </NotificationContainer>
            <Heading>
              Transform Your Brand,
              <br />
              Elevate Your Impact!
            </Heading>
            <PrimaryAction to="/boost-your-brand">Boost Your Brand!</PrimaryAction>
          </LeftColumn>
          <RightColumn />
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
