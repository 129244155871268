// import React from "react";
// import tw from "twin.macro";
// import styled from "styled-components";
// import { css } from "styled-components/macro"; // eslint-disable-line
// import {
//   SectionHeading,
//   Subheading as SubheadingBase,
// } from "Components/misc/Headings";
// import { PrimaryButton as PrimaryButtonBase } from "Components/misc/Buttons";
// import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
// import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

// const Container = styled.div`
//   ${tw`relative mt-4 mb-8`}
//   background-color: #f5d7da;
//   box-shadow: 4px 8px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(0, 0, 0, 0.2);
//   border-bottom-right-radius: 30rem;
//   @media (max-width: 768px) {
//     border-bottom-right-radius: 15rem;
//   }
//   padding-bottom: 6rem;
//   @media (max-width: 768px) {
//     padding-bottom: 10rem;
//   }
// `;
// const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
// const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0 `;
// const DropColumn = styled(Column)`
//   ${tw`md:w-5/12 flex-shrink-0 h-auto relative md:mt-0`}
//   margin-top: -4rem;
//   @media (max-width: 768px) {
//     margin-top: 0;
//     ${tw`w-full`}
//   }
// `;
// const positionStyles = (props) =>
//   props.textOnLeft
//     ? tw`md:mr-12 lg:mr-16 md:order-first`
//     : tw`md:ml-12 lg:ml-16 md:order-last`;
// const TextColumn = styled(Column)((props) => [
//   tw` mt-16 md:mt-0 text-left sm:px-8 py-8`,
// ]);

// const TextContent = tw.div`lg:py-8 text-center md:text-left`;

// const SubheadingWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   width: 100%;
//   padding:1rem;
//   margin: 10px 0;
// `;

// const Line = styled.div`
//   width: 50px;
//   height: 1px;
//   background-color: grey;
// `;

// const Text = styled.h2`
//   padding: 0 5px;
//   color: #ff5464;
//   font-size: 20px;
//   white-space: nowrap;
// `;

// const Heading = tw(
//   SectionHeading
// )`mt-4 p-2 font-black text-left text-3xl sm:text-4xl sm:text-left lg:text-5xl md:text-left leading-tight`;
// const Description = tw.p`mt-4 p-2 text-left md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

// const Features = styled.div`
//   ${tw`mt-8 md:mt-0 flex flex-col md:flex-row items-start md:items-start`}
//   gap: 2rem;
//    @media (max-width: 768px) {
//     ${tw`flex-row mt-24 items-start p-6`}
//     gap: 1rem;
//   }
//   position: relative;
// `;

// const Feature = styled.div`
//   ${tw`px-2 py-8 bg-white shadow-lg text-center md:text-left relative transition-transform duration-300 rounded-b-full flex flex-col items-center`}
//   width: 16rem;
//   height: ${(props) =>
//     props && props.value === "feature-professional-team"
//       ? "489px"
//       : "379px"};
//   &:hover {
//     transform: translateY(-10px);
//   }
//   @media (max-width: 768px) {
//     ${tw`w-40 mt-0`}
// height: ${(props) =>
//     props && props.value === "feature-professional-team"
//       ? "350px"
//       : "290px"};
//     margin-top: 0px;
//   }
// `;

// const Spacer = tw.div`flex-grow`;

// const SingleHangingLine = styled.div`
//   ${tw`absolute top-0 left-0 w-full`}
//   height: 1px;
//   background-color: rgba(205, 205, 205, 1);
//   box-shadow: 0 -4px 8px rgba(0, 0, 0, 1);
//   top: -1px;
//   @media (max-width: 768px) {
//     ${tw`flex-row items-center`}
//     box-shadow: 0 0px 0px rgba(0, 0, 0, 1);
//     top: 22px;
//   }
// `;

// const FeatureText = styled.div`
//   ${tw`flex flex-col text-center gap-4`}
//   margin-top: auto;
//   margin-bottom: 30px;
//   padding: 1rem 0;

// `;

// const FeatureHeading = styled.div`${tw`font-bold text-2xl  text-black mt-4 md:mt-0`}
// @media(max-width:768px){
// font-size:20px
// }

// `;
// const FeatureDescription = tw.div`mt-1 text-sm`;

// const PrimaryButton = tw(
//   PrimaryButtonBase
// )`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

// export default ({
//   subheading = "Our Expertise",
//   heading = (
//     <>
//       We have the most{" "}
//       <span tw="text-customPurple">innovative</span> and{" "}
//       <span tw="text-customPurple">skilled</span> team in the industry.
//     </>
//   ),
//   description =
//     "Our team of experts excels in delivering top-notch web services that drive business growth. From web development to digital marketing, we offer comprehensive solutions to elevate your online presence.",
//   primaryButtonText = "Explore Our Services",
//   primaryButtonUrl = "#",
//   features = null,
//   textOnLeft = true,
// }) => {
//   const defaultFeatures = [
//     {
//       Icon: BriefcaseIcon,
//       title: "Professional Team",
//       description:
//         "Our team consists of experienced professionals who are dedicated to delivering the best results for your business.",
//       id: "feature-professional-team",
//     },
//     {
//       Icon: MoneyIcon,
//       title: "Cost-Effective Solutions",
//       description:
//         "We offer competitive pricing without compromising on quality, ensuring you get the best value for your investment.",
//       id: "feature-cost-effective-solutions",
//     },
//   ];

//   if (!features) features = defaultFeatures;

//   return (
//     <Container>
//       <TwoColumn>
//         <TextColumn textOnLeft={textOnLeft}>
//           <TextContent>
//             <SubheadingWrapper>
//               <Line />
//               <Text>{subheading}</Text>
//               <Line />
//             </SubheadingWrapper>
//             <Heading>{heading}</Heading>
//             <Description>{description}</Description>
//             <PrimaryButton as="a" href={primaryButtonUrl}>
//               {primaryButtonText}
//             </PrimaryButton>
//           </TextContent>
//         </TextColumn>
//         <DropColumn>
//           <Features>
//             <SingleHangingLine />
//             {features.map(({ title, description, id }) => (
//               <Feature key={id} value={id}>
//                 <FeatureText>
//                   <FeatureHeading>{title}</FeatureHeading>
//                   <FeatureDescription>{description}</FeatureDescription>
//                 </FeatureText>
//               </Feature>
//             ))}
//           </Features>
//         </DropColumn>
//       </TwoColumn>
//     </Container>
//   );
// };

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import CEOImage from "images/ceo-photo.png"; // Replace with your correct image path
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

const Container = styled.div`
  ${tw`relative`}
`;

const TopSection = styled.div`
  ${tw`relative flex flex-col justify-center px-8 py-8`}
  background-color: #000;
  color: #fff;
`;

const BottomSection = styled.div`
  ${tw`relative flex flex-col items-start gap-10 xs:p-0 sm:p-0 md:p-16 lg:p-16 xl:p-10 p-20 py-12`}
  background-color: #ff5464;
  border-bottom-left-radius: 8rem;

  @media (max-width: 768px) {
    border-bottom-left-radius: 6rem;
    padding: 2rem;
  }
`;

const TextBlock = styled.div`
  ${tw`text-left text-2xl xs:p-0 xs:w-full sm:w-full cmd:w-full xl:w-2/4 lg:w-2/4 sm:p-0 md:py-10 lg:py-10 xl:py-10 md:px-16 lg:px-16 xl:px-16 md:w-full leading-relaxed mb-8`}
  color: #fff;
`;

const HighlightText = styled.p`
  ${tw`text-left text-2xl xl:w-2/4 lg:w-2/4 sm:p-0 md:py-4 lg:py-4 xl:py-4 md:px-12 lg:px-12 xl:px-12 font-semibold mt-4`}
  color: white;
`;

const ProfileWrapper = styled.div`
  ${tw`flex flex-row items-center md:px-12 lg:px-12 xl:px-12 gap-8 justify-start w-full`}

  @media (max-width: 960px) {
    ${tw` items-center text-center`}
  }
`;

const ProfileImage = styled.img`
  ${tw`w-32 h-32 rounded-full`}
  object-fit: cover; /* Ensures the image is cropped and fills the container */
  transform: scale(1.2); /* Zoom effect (1.2x) */
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.3); /* Optional: Slight zoom-in effect on hover */
  }
`;

const ProfileText = styled.div`
  ${tw`text-white px-4 text-left`}
`;

const ProfileName = styled.h3`
  ${tw`font-bold text-2xl`}
`;

const ProfileRole = styled.p`
  ${tw`text-lg`}
`;

const CEOImageWrapper = styled.div`
  ${tw`absolute right-[10rem] top-[5rem]`};
  z-index: 2; /* Ensure image appears above all other layers */

  img {
    width: 400px;
    height: auto;
    border-radius: 1rem;

    @media (max-width: 1280px) {
      display: none; /* Hides the image wrapper on smaller screens */
    }
  }
`;

const QuoteIconLeft = styled(FaQuoteLeft)`
  ${tw`text-4xl text-red-500 mb-4`}
`;

const QuoteIconRight = styled(FaQuoteRight)`
  ${tw`text-4xl text-red-500 mt-4`}
`;

export default () => {
  return (
    <Container>
      {/* Top Section */}
      <TopSection>
        <TextBlock>
          <QuoteIconLeft />
          <p>
            At MilaajBrandset, we believe that every brand has a unique story to
            tell. Our mission is to transform your vision into reality through
            innovative strategies and creative solutions. We’re committed to
            partnering with you every step of the way to ensure your brand not
            only stands out but thrives in today’s competitive landscape.
          </p>
          <QuoteIconRight />
        </TextBlock>
        <CEOImageWrapper>
          <img src={CEOImage} alt="CEO" />
        </CEOImageWrapper>
      </TopSection>

      {/* Bottom Section */}
      <BottomSection>
        {/* Text First Row */}
        <HighlightText>
          Every great brand starts with a powerful story. Let’s create yours
          together.
        </HighlightText>

        {/* Profile Wrapper in the Second Row */}
        <ProfileWrapper>
          <ProfileImage src={CEOImage} alt="CEO" />
          <ProfileText>
            <ProfileName>Midhaj K</ProfileName>
            <ProfileRole>Founder and CEO</ProfileRole>
          </ProfileText>
        </ProfileWrapper>
      </BottomSection>
    </Container>
  );
};
