import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Header from 'Components/headers/PageHeader'
import Features from "Components/Features/ThreeColumnPages";

import WEB1 from "images/WEB-1.avif"
import WEB2 from "images/WEB-2.avif"
import WEB3 from "images/WEB-3.avif"
import WEB4 from "images/WEB-4.avif"
import WEB5 from "images/WEB-5.avif"
import WEB6 from "images/WEB-6.avif"


import { FaLaptopCode, FaMobileAlt, FaRocket } from "react-icons/fa";


import WebDevIconImage from "images/web-dev-icon.svg";
import ResponsiveIconImage from "images/responsive.svg";
import PerformanceIconImage from "images/performance.svg";
import styled from "styled-components";
import ContactRightColum from "Components/Forms/ContactFormCol";

const Subheading = tw.span`uppercase tracking-wider text-sm`;


const LeftColumn = tw.div`w-[95%] md:w-3/5 px-4 flex flex-col space-y-8`;
const RightColumn = styled.div`
  ${tw`w-full md:w-2/5 flex items-center justify-center mt-12 md:mt-0`}
`;

// Adjust TwoColumn to include overflow-hidden and ensure centering
const TwoColumn = styled.div`
  ${tw`flex flex-col md:flex-row justify-center items-stretch gap-10 max-w-screen-4xl mx-auto py-12 md:py-16 lg:py-16 overflow-hidden`}
`;

export default () => {
  return (
    <AnimationRevealPage>
  <Header from={"Web Development"} />
  <TwoColumn>
    <LeftColumn>
  <MainFeature1
    subheading={<Subheading>Web Development Experts</Subheading>}
    heading="Cutting-Edge Web Solutions for Your Business"
    buttonRounded={false}
    primaryButtonText="Get Started"
    textOnLeft={true}

    // imageSrcs={[
    //   WEB1,
    //   WEB2,
    //   WEB3
    // ]}
    description="Unlock the potential of your business with our innovative web development services. At MilaajBrandset, we design and develop custom websites that are tailored to your specific needs, ensuring a strong online presence and superior user experience."
    widths={["70%", "70%", "70%"]} 
    heights={["50%", "50%", "50%"]} 
    imageColumnStyle={`height: 400px;`}
    imageStyle={`width: 45%; height: 60%;`}
    imagePositions={[
      "left: 0%; top: 0%;",
      "right: 30%; top: 55%;",
      "left: 30%; bottom: 20%;",
    ]}
  />
  <MainFeature1
    subheading={<Subheading>Our Approach</Subheading>}
    heading="User-Centric Web Development"
    buttonRounded={false}
    primaryButtonText="Learn More"
    // imageSrcs={[
    //   WEB4,
    //   WEB5,
    //   WEB6
    // ]}
    description="Our web development approach is centered on the user. We focus on creating intuitive, engaging, and accessible websites that provide an exceptional user experience. By leveraging the latest technologies and best practices, we ensure your website not only looks great but also performs flawlessly."
    textOnLeft={true}
    widths={["70%", "70%", "70%"]} 
    heights={["50%", "50%", "50%"]} 
    imageColumnStyle={`height: 400px;`}
    imageStyle={`width: 45%; height: 60%;`}
    imagePositions={[
      "left: 0%; top: 0%;",
      "right: -10%; top: 20%;",
      "left: 10%; bottom: 0;",
    ]}
  />
  </LeftColumn>
  <RightColumn>
    <ContactRightColum />
  </RightColumn>
  </TwoColumn>
      <Features
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Web Development"
        description="MilaajBrandset offers end-to-end web development services to create powerful, scalable, and user-friendly websites that drive your business growth. Our team of experts works closely with you to deliver customized solutions that meet your specific needs."
        cards={[
          {
            icon: <FaLaptopCode size={40} color="#ff5464" aria-label="Custom Web Development" />,
            title: "Custom Web Development",
            description: 
              "Tailor-made websites aligned with your brand identity, designed to engage users and drive conversions effectively.",
            // url: "/web-development/custom-web-development",
          },
          {
            icon: <FaMobileAlt size={40} color="#ff5464" aria-label="Responsive Design" />,
            title: "Responsive Design",
            description: 
              "Websites optimized for seamless performance and appearance across all devices, ensuring user satisfaction.",
            // url: "/web-development/responsive-design",
          },
          {
            icon: <FaRocket size={40} color="#ff5464" aria-label="Performance Optimization" />,
            title: "Performance Optimization",
            description: 
              "Fast-loading websites designed for enhanced user experiences and higher SEO rankings, improving online visibility.",
            // url: "/web-development/performance-optimization",
          },
        ]}
        
        linkText=""
      />
    </AnimationRevealPage>
  );
};
