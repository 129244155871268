// src/pages/SocialMediaBranding.js

import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";

import { FaPalette, FaCameraRetro, FaComments } from "react-icons/fa";

import ContactRightColum from "Components/Forms/ContactFormCol";

const HighlightedText = tw.span`text-customPurple`;


const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;



const LeftColumn = tw.div`w-[95%] md:w-3/5 px-4 flex flex-col space-y-8`;
const RightColumn = styled.div`
  ${tw`w-full md:w-2/5 flex items-center justify-center mt-12 md:mt-0`}
`;

// Adjust TwoColumn to include overflow-hidden and ensure centering
const TwoColumn = styled.div`
  ${tw`flex flex-col md:flex-row justify-center items-stretch gap-10 max-w-screen-4xl mx-auto py-12 md:py-16 lg:py-16 overflow-hidden`}
`;

export default () => {
  return (
      <AnimationRevealPage>
        <Header from={"Social Media Branding"} />
        <TwoColumn>
          <LeftColumn>
        {/* Main Feature Section */}
        <MainFeature1
          subheading={<Subheading>Social Media Branding</Subheading>}
          heading={
            <>
              Create a <HighlightedText>Strong Brand Presence</HighlightedText>
            </>
          }
          buttonRounded={false}
          primaryButtonText="Get Started"
          primaryButtonUrl="/get-started" // URL for the button
          // imageSrcs={[
          //   "https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          //   "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
          //   "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          // ]}
          description={
            <Description>
              At MilaajBrandset, we specialize in creating custom websites that perfectly align with your brand identity and business objectives. Our expert team develops robust, scalable, and user-friendly websites that drive engagement and conversions. Whether you're looking to build a new website or revamp an existing one, we have the skills and experience to deliver exceptional results.
            </Description>
          }
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]} // Custom positions for each image
          textOnLeft={false}
        />

        {/* Our Approach Section */}
        <MainFeature1
          subheading={<Subheading>Our Approach</Subheading>}
          heading={<Heading>Comprehensive Social Media Branding Solutions</Heading>}
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/our-approach" // URL for the button
          // imageSrcs={[
          //   "https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          //   "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
          //   "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          // ]}
          description={
            <Description>
              Our approach is meticulous and client-focused. We begin by understanding your business goals, target audience, and unique requirements. From there, we design a strategic plan that encompasses brand identity development, visual content creation, and consistent brand voice and messaging. Our team collaborates closely with you throughout the branding process, providing regular updates and incorporating your feedback to achieve the best possible outcome.
            </Description>
          }
          textOnLeft={true}
          widths={["50%", "55%", "70%"]}
          heights={["60%", "70%", "40%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 13%; top: 20%;",
            "right: 0; top: 10%;",
            "left: 10%; bottom: 0;",
          ]} // Custom positions for each image
        />
        </LeftColumn>
        <RightColumn>
          <ContactRightColum />
        </RightColumn>
        </TwoColumn>

        {/* Our Social Media Branding Services Section */}
        <StyledFeatures
          subheading={<Subheading>Our Branding Services</Subheading>}
          heading={
            <>
              Comprehensive <HighlightedText>Social Branding Solutions</HighlightedText>
            </>
          }
          description="MilaajBrandset offers a full suite of Social Media Branding services designed to elevate your online presence and engage your audience effectively. Our services are tailored to meet your unique needs, ensuring a cohesive and impactful branding strategy."
          cards={[
            {
              icon: <FaPalette size={40} color="#ff5464" aria-label="Brand Identity Development" />,
              title: "Brand Identity Development",
              description: "We create a cohesive brand identity that resonates across all social media platforms.",
              url: "/social-media-branding/brand-identity-development",
            },
            {
              icon: <FaCameraRetro size={40} color="#ff5464" aria-label="Visual Content Creation" />,
              title: "Visual Content Creation",
              description: "We design eye-catching visuals that align with your brand and engage your audience.",
              url: "/social-media-branding/visual-content-creation",
            },
            {
              icon: <FaComments size={40} color="#ff5464" aria-label="Brand Voice & Messaging" />,
              title: "Brand Voice & Messaging",
              description: "We develop a consistent brand voice and messaging strategy for all your social media communications.",
              url: "/social-media-branding/brand-voice-messaging",
            },
            // Add more services if needed
          ]}
          linkText=""
        />
      </AnimationRevealPage>
  );
};
