import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Header from "Components/headers/PageHeader";
import Features from "Components/Features/ThreeColumnPages";

import API1 from "images/API-1.avif";
import API2 from "images/API-2.avif";
import API3 from "images/API-3.avif";
import API4 from "images/API-4.avif";
import API5 from "images/API-5.avif";
import API6 from "images/API-6.avif";

import ApiIconImage from "images/customAPIIcon.webp";
import SecurityIconImage from "images/security.svg";
import SupportIconImage from "images/Api_Support_Icon.webp";
import styled from "styled-components";
import ContactRightColum from "Components/Forms/ContactFormCol";
import { FaCode, FaShieldAlt, FaTools } from "react-icons/fa";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const LeftColumn = tw.div`w-[95%] md:w-3/5 px-4 flex flex-col space-y-8`;
const RightColumn = styled.div`
  ${tw`w-full md:w-2/5 flex items-center justify-center mt-12 md:mt-0`}
`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

// Adjust TwoColumn to include overflow-hidden and ensure centering
const TwoColumn = styled.div`
  ${tw`flex flex-col md:flex-row justify-center items-stretch gap-10 max-w-screen-4xl mx-auto py-12 md:py-16 lg:py-16 overflow-hidden`}
`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header from={"Application Programming Interface Development"} />
      <TwoColumn>
        <LeftColumn>
          <MainFeature1
            subheading={<Subheading>API Development Experts</Subheading>}
            heading="Powerful APIs for Your Business"
            buttonRounded={false}
            textOnLeft={true}
            primaryButtonText="Get Started"
            // imageSrcs={[API1, API2, API3]}
            description="Unlock the full potential of your business with our robust API development services. At MilaajBrandset, we design and develop powerful APIs that facilitate seamless integration and automation, ensuring your systems communicate efficiently and effectively."
            widths={["65%", "55%", "70%"]}
            heights={["60%", "70%", "50%"]}
            imageColumnStyle={`height: 400px;`}
            imageStyle={`width: 45%; height: 60%;`}
            imagePositions={[
              "left: 13%; top: 20%;",
              "right: 0; top: 40%;",
              "left: 0%; bottom: 0;",
            ]}
          />
          <MainFeature1
            subheading={<Subheading>Our Approach</Subheading>}
            heading="Customized API Solutions"
            buttonRounded={false}
            primaryButtonText="Learn More"
            // imageSrcs={[API4, API5, API6]}
            description="Our approach to API development is centered on customization and precision. We take the time to understand your unique business needs and create APIs that are tailored to meet those needs. Whether you're looking to enhance your current systems or develop new integrations, our team of experts is here to deliver top-notch solutions."
            textOnLeft={true}
            widths={["50%", "55%", "57%"]}
            heights={["57%", "60%", "40%"]}
            imageColumnStyle={`height: 400px;`} // Adjusting height as required
            imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
            imagePositions={[
              "left: 40%; top: 30%;",
              "right: 38%; top: 10%;",
              "left: 0%; bottom: 0;",
            ]} // Custom positions for each image
          />
        </LeftColumn>
        <RightColumn>
          <ContactRightColum />
        </RightColumn>
      </TwoColumn>
      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive API Development"
        description="MilaajBrandset offers end-to-end API development services to streamline your business processes and enhance connectivity. From initial consultation to deployment and maintenance, we ensure that your APIs are robust, secure, and scalable."
        cards={[
          {
            icon: <FaCode size={40} color="#ff5464" aria-label="Custom API Development" />, 
            title: "Custom API Development",
            description:
              "We craft APIs tailored to align with your business needs, integrating seamlessly to enhance efficiency.",
            // url:"/custom-api-development"
          },
          {
            icon: <FaShieldAlt size={40} color="#ff5464" aria-label="API Security" />, 
            title: "API Security",
            description:
              "Secure APIs with advanced protocols, ensuring data protection and safe transactions for your business.",
            // url:"/api-security"
          },
          {
            icon: <FaTools size={40} color="#ff5464" aria-label="API Maintenance & Support" />, 
            title: "API Maintenance & Support",
            description:
              "Ongoing support ensures smooth API operation with updates and troubleshooting for optimal performance.",
            // url:"/api-maintenance-support"
          },
        ]}
        
        linkText=""
      />
    </AnimationRevealPage>
  );
};
