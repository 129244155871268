import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Header from "Components/headers/PageHeader";
import Features from "Components/Features/ThreeColumnPages";
import { FaSearch, FaPenNib, FaChartBar, FaBullhorn, FaEnvelopeOpenText, FaUserFriends } from "react-icons/fa";

import DM1 from "images/dM-1.avif";
import DM2 from "images/dM-2.webp";
import DM3 from "images/dM-3.webp";

import DM5 from "images/dM-5.avif";
import DM6 from "images/dM-6.avif";
import DM7 from "images/dM-7.avif";

import SeoIconImage from "images/seo-icon.svg";
import ContentIconImage from "images/content-icon.svg";
import AnalyticsIconImage from "images/analytics-icon.svg";
import SocialMediaIconImage from "images/social-media-icon.svg";
import EmailMarketingIconImage from "images/email-marketing-icon.svg";
import SocialMediaBrandingIcon from "images/social-media-branding-icon.svg";
import ContactUs from "./ContactUs";
import ContactForm from "Components/Forms/TwoColContactUsWithIllustrationFullForm";
import ContactRightColum from "Components/Forms/ContactFormCol";

// Define styled components with corrected syntax
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

// Define LeftColumn and RightColumn with proper tw syntax
const LeftColumn = tw.div`w-[95%] md:w-3/5 px-4 flex flex-col space-y-8`;
const RightColumn = styled.div`
  ${tw`w-[95%] mx-2 md:w-2/5 flex  items-center justify-center mt-12 md:mt-0`}
`;

// Adjust TwoColumn to include overflow-hidden and ensure centering
const TwoColumn = styled.div`
  ${tw`flex flex-col md:flex-row justify-center items-stretch gap-10 max-w-screen-4xl mx-auto py-12 md:py-16 lg:py-16 overflow-hidden`}
`;

// // Corrected ContactFormWrapper with proper tw syntax
// const ContactFormWrapper = styled.div`
//   ${tw`bg-white p-8 rounded-4xl shadow-lg`}
//   width: 100%;
//   max-width: 400px;
//   height: 60%; /* Adjust as needed */
//   display: flex;
//   flex-direction: column;
//   justify-content: center; /* Center the form vertically */
  
//   form {
//     ${tw`flex flex-col space-y-6`} /* Increased space for better readability */
//     flex-grow: 1; /* Allow the form to grow and fill the container */
//   }
  
//   label {
//     ${tw`block text-sm text-left font-medium text-gray-700 mb-4`}
//   }
  
//   input{
//     ${tw`border border-gray-300 p-4 rounded focus:outline-none focus:ring-2 focus:ring-red-500`}
//   }

//   textarea{
//   ${tw`px-24 py-12`}
  
//   button {
//     ${tw`bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition-colors duration-300`}
//   }
// `;

// const ContactForm = () => (
//   <ContactFormWrapper>
//     <h2 tw="text-lg font-bold mb-6 text-center">How May We Help You?</h2>
//     <form>
//       {/* Name Field */}
//       <div>
//         <label htmlFor="name">Name</label>
//         <input
//           id="name"
//           type="text"
//           placeholder="Enter Your Name"
//           required
//           aria-required="true"
//         />
//       </div>
      
//       {/* Email Field */}
//       <div>
//         <label htmlFor="email">Email</label>
//         <input
//           id="email"
//           type="email"
//           placeholder="Enter Your Email"
//           required
//           aria-required="true"
//         />
//       </div>
      
//       {/* Mobile Number Field */}
//       <div>
//         <label htmlFor="mobile">Mobile Number</label>
//         <input
//           id="mobile"
//           type="tel"
//           placeholder="Enter Your Mobile Number"
//           required
//           aria-required="true"
//         />
//       </div>
      
//       {/* Message Field */}
//       <div>
//         <label htmlFor="message">Message</label>
//         <textarea
//           id="message"
//           placeholder="Your Message"
//           rows="4"
//           required
//           aria-required="true"
//         ></textarea>
//       </div>
      
//       {/* Submit Button */}
//       <button type="submit">Send Enquiry</button>
//     </form>
//   </ContactFormWrapper>
// );

export default function MainPage() {
  return (
    <AnimationRevealPage>
      <Header from={"Digital Marketing"} />
      <TwoColumn>
        {/* Left Column: Features */}
        <LeftColumn>
          <MainFeature1
            subheading={<Subheading>Digital Marketing Experts</Subheading>}
            heading={
              <Heading>Boost Your Online Presence with MilaajBrandset</Heading>
            }
            buttonRounded={false}
            primaryButtonText="Learn More"
            primaryButtonUrl="/learn-more" // Add the URL for the button
            // imageSrcs={[DM1, DM3, DM2]}
            description={
              <Description>
                At MilaajBrandset, we specialize in enhancing your digital
                footprint. Our team of experts utilizes cutting-edge techniques to
                ensure your brand stands out in the crowded digital landscape. With
                our comprehensive digital marketing services, we help you attract,
                engage, and convert more leads.
              </Description>
            }
            textOnLeft={true}
            widths={["55%", "60%", "70%"]}
            heights={["45%", "60%", "35%"]}
            imageColumnStyle={`height: 400px;`} // Adjusting height as required
            imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
            imagePositions={[
              "left: 35%; top: 20%;",
              "right: 30%; top: 10%;",
              "left: 32%; bottom: 20%;",
            ]}
          />

          <MainFeature1
            subheading={<Subheading>Our Approach</Subheading>}
            heading={<Heading>Data-Driven Digital Marketing Strategies</Heading>}
            buttonRounded={false}
            primaryButtonText="Learn More"
            primaryButtonUrl="/learn-more-approach"
            // imageSrcs={[DM6, DM5, DM7]}
            description={
              <Description>
                Our approach is rooted in data and driven by results. We leverage
                the power of analytics to craft personalized marketing strategies
                that align with your business goals. From SEO and social media
                marketing to email campaigns and detailed analytics, we cover all
                aspects to ensure your digital success.
              </Description>
            }
            textOnLeft={true}
            widths={["50%", "55%", "70%"]}
            heights={["60%", "70%", "40%"]}
            imageColumnStyle={`height: 400px;`} // Adjusting height as required
            imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
            imagePositions={[
              "left: 13%; top: 20%;",
              "right: 0; top: 10%;",
              "left: 10%; bottom: 0;",
            ]}
          />
        </LeftColumn>

        {/* Right Column: Contact Form */}
        <RightColumn>
          <ContactRightColum />
        </RightColumn>
      </TwoColumn>

      <StyledFeatures
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Digital Marketing Solutions"
        description="MilaajBrandset offers a full suite of digital marketing services to elevate your brand's online presence and drive measurable results.
            Our services are designed to cater to all your digital marketing
            needs, ensuring a cohesive and effective strategy."
            cards={[
              {
                icon: <FaSearch size={40} color="#ff5464" aria-label="Search Engine Optimization" />,
                title: "Search Engine Optimization",
                description: "Enhance your website's visibility in search results with expert strategies tailored to your business.",
                // url: "/seo",
              },
              {
                icon: <FaPenNib size={40} color="#ff5464" aria-label="Content Marketing" />,
                title: "Content Marketing",
                description: "Engage your audience with compelling content strategies designed to attract, retain, and convert effectively.",
                // url: "/content-marketing",
              },
              {
                icon: <FaChartBar size={40} color="#ff5464" aria-label="Analytics Services" />,
                title: "Analytics Services",
                description: "Empower your business with actionable insights and decisions through advanced analytics and detailed reporting.",
                // url: "/analytics-services",
              },
              {
                icon: <FaBullhorn size={40} color="#ff5464" aria-label="Social Media Marketing" />,
                title: "Social Media Marketing",
                description: "Increase your brand's influence with marketing strategies that engage and convert on social platforms.",
                // url: "/social-media-marketing",
              },
              {
                icon: <FaEnvelopeOpenText size={40} color="#ff5464" aria-label="Email Marketing" />,
                title: "Email Marketing",
                description: "Deliver targeted email campaigns designed to engage, nurture, and convert leads into loyal customers.",
                // url: "/email-marketing",
              },
              {
                icon: <FaUserFriends size={40} color="#ff5464" aria-label="Social Media Branding" />,
                title: "Social Media Branding",
                description: "Build a cohesive and impactful brand presence across all platforms to resonate with your audience.",
                // url: "/social-media-branding",
              },
            ]}
            
        linkText=""
      />
    </AnimationRevealPage>
  );
}
