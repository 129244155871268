import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "Components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "Components/misc/Buttons";
import EmailIllustrationSrc from "images/email-illustration.svg";

// Add your background image source here
import BackgroundImage from "images/contactus.avif"; // Update this path
import { Contact } from "lucide-react";
import { useNavigate } from "react-router-dom";


const MainContainer = styled.div`
${tw`inset-0 pb-6 text-center `}
background-color:rgba(0, 0, 0, 0.8);
z-index:1
`

const Container = styled.div`
  ${tw`relative`}
  background-image: url("${BackgroundImage}");
  background-size: cover; 
  background-position: center; 
  background-attachment: fixed;
  background-repeat: no-repeat; 
  border-bottom-left-radius: 100px; /* Adjust radius as needed */
  border-bottom-right-radius: 100px; /* Adjust radius as needed */
  overflow: hidden; /* Ensure content stays within the rounded corners */
`;


export const Overlay = styled.div`
  ${tw`absolute inset-0 bg-black `} 
  opacity: 0.8; /* Adjust opacity for better visibility */
  z-index: 10; /* Ensure overlay is above the background but below text */
`;

const TwoColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-0 md:mt-0 text-center`, // Center text alignment
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const TextContent = tw.div`lg:py-4 text-white relative z-10 font-poppins`; // Ensure text is above overlay

const Heading = tw(SectionHeading)`mt-4 text-white text-3xl sm:text-4xl lg:text-5xl leading-tight`;
const Description = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row justify-center items-center`; // Center the form

const SubmitButton = tw(PrimaryButtonBase)`inline-block lg:ml-6 mt-6 w-[320px] h-[63px] lg:mt-0 text-base`;


export default ({
  heading = <>Contact <span tw="text-primary-500">Us</span></>,
  description = "Associate with MilaajBrandset to bring change and transform your business to reach in heights.",
  submitButtonText = "Let's Collaborate Together",
  formAction = "#",
  formMethod = "post",
  textOnLeft = true,
}) => {
  const navigate = useNavigate()
  const Contact =( ) =>{
    navigate('/contact')
  }
  return (
    <MainContainer>
    <Container>
      <Overlay /> {/* Dark overlay for better text visibility */}
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Form action={formAction} method={formMethod}>
              <SubmitButton onClick={Contact} type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  </MainContainer>
  );
};
