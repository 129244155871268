// src/pages/VideoAdProduction.js

import React from "react";
import { FaCut, FaRegFileAlt, FaEdit, FaAd } from "react-icons/fa";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";
import ContactRightColum from "Components/Forms/ContactFormCol";



// Styled Components
const HighlightedText = tw.span`text-customPurple`;

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

const LeftColumn = tw.div`w-[95%] md:w-3/5 px-4 flex flex-col space-y-8`;
const RightColumn = styled.div`
  ${tw`w-full md:w-2/5 flex items-center justify-center mt-12 md:mt-0`}
`;

// Adjust TwoColumn to include overflow-hidden and ensure centering
const TwoColumn = styled.div`
  ${tw`flex flex-col md:flex-row justify-center items-stretch gap-10 max-w-screen-4xl mx-auto py-12 md:py-16 lg:py-16 overflow-hidden`}
`;

const VideoAdProduction = () => {
  return (
    <AnimationRevealPage>
      <Header from={"Video Ad Production"} />
      <TwoColumn>
        <LeftColumn>
          {/* Main Feature Section */}
          <MainFeature1
            subheading={<Subheading>Video Ad Production</Subheading>}
            heading={
              <>
                Create <HighlightedText>Impactful Video Advertisements</HighlightedText>
              </>
            }
            buttonRounded={false}
            primaryButtonText="Get Started"
            primaryButtonUrl="/get-started" // URL for the button
            // imageSrcs can be uncommented and updated with relevant images if available
            // imageSrcs={[
            //   "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            //   "https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            //   "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            // ]}
            description={
              <Description>
                Create impactful video advertisements that tell your brand’s story. From concept to final edit, we produce high-quality videos designed to engage and convert your audience. Our expert team leverages the latest technology and creative techniques to deliver videos that resonate with your target market and drive measurable results.
              </Description>
            }
            widths={["55%", "60%", "70%"]}
            heights={["45%", "60%", "35%"]}
            imageColumnStyle={`height: 400px;`} // Adjusting height as required
            imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
            imagePositions={[
              "left: 35%; top: 20%;",
              "right: 30%; top: 10%;",
              "left: 32%; bottom: 20%;",
            ]} // Custom positions for each image
            textOnLeft={false}
          />

          {/* Our Approach Section */}
          <MainFeature1
            subheading={<Subheading>Our Approach</Subheading>}
            heading={<Heading>Comprehensive Video Production Strategies</Heading>}
            buttonRounded={false}
            primaryButtonText="Learn More"
            primaryButtonUrl="/our-approach" // URL for the button
            // imageSrcs can be uncommented and updated with relevant images if available
            // imageSrcs={[
            //   "https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            //   "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
            //   "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            // ]}
            description={
              <Description>
                Our approach is meticulous and client-focused. We begin by understanding your business goals, target audience, and unique requirements. From there, we design a strategic plan that encompasses concept development, scriptwriting, professional editing, and targeted distribution. Our team collaborates closely with you throughout the production process, providing regular updates and incorporating your feedback to achieve the best possible outcomes.
              </Description>
            }
            textOnLeft={true}
            widths={["50%", "55%", "70%"]}
            heights={["60%", "70%", "40%"]}
            imageColumnStyle={`height: 400px;`} // Adjusting height as required
            imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
            imagePositions={[
              "left: 13%; top: 20%;",
              "right: 0; top: 10%;",
              "left: 10%; bottom: 0;",
            ]} // Custom positions for each image
          />
        </LeftColumn>
        <RightColumn>
          <ContactRightColum />
        </RightColumn>
      </TwoColumn>

      {/* Our Video Ad Production Services Section */}
      <StyledFeatures
        subheading={<Subheading>Our Video Production Services</Subheading>}
        heading={
          <>
            Comprehensive <HighlightedText>Video Ad Solutions</HighlightedText>
          </>
        }
        description="MilaajBrandset offers a full suite of Video Ad Production services designed to create engaging and effective advertisements that resonate with your target audience. Our services are tailored to meet your unique needs, ensuring a cohesive and impactful video strategy."
        cards={[
          {
            icon: <FaCut size={40} color="#ff5464" aria-label="Concept Development" />,
            title: "Concept Development",
            description: "Generate compelling ideas that align with your brand.",
            url: "/video-ad-production/concept-development",
          },
          {
            icon: <FaRegFileAlt size={40} color="#ff5464" aria-label="Scriptwriting" />,
            title: "Scriptwriting",
            description: "Develop scripts that effectively communicate your message.",
            url: "/video-ad-production/scriptwriting",
          },
          {
            icon: <FaEdit size={40} color="#ff5464" aria-label="Professional Editing" />,
            title: "Professional Editing",
            description: "Deliver polished and engaging videos.",
            url: "/video-ad-production/professional-editing",
          },
          // {
          //   icon: <FaAd size={40} color="#ff5464" aria-label="Targeted Distribution" />,
          //   title: "Targeted Distribution",
          //   description: "Reach the right audience with strategic placements.",
          //   url: "/video-ad-production/targeted-distribution",
          // },
          // Add more services if needed
        ]}
        linkText=""
      />
    </AnimationRevealPage>
  );
};

export default VideoAdProduction;
